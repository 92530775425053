.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-left: solid 20px;
    border-radius: 15px;
    border-color: #EEF2F6;
    margin-right: 35px;
    border-right: solid 20px;
    border-color: #EEF2F6;
    border-bottom: solid 20px;
    border-color: #EEF2F6;
  }
  
  .tableCell {
    border-bottom: 1px solid #ddd;
    padding: 8px;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    border-top: solid 20px;
    border-color: #EEF2F6;
    margin-bottom: 2px;
    padding-top: 16px;
  
  }
  
  .button {
    color: #fff;
    background-color: #1976d2;
  
    &:hover {
      background-color: #1565c0;
    }
  
    margin-left: 8px;
    margin-left: 1;
    margin-right: 1;
  }