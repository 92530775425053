.react-datepicker__close-icon {
    justify-content: center;
    display: flex;
    align-items: center;
}

.react-datepicker__close-icon::after {
    background-color: white !important;
    color: black;
    font-size: large;
    vertical-align: middle;
    content: 'X';
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

