.fullContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-left: solid 20px;
  border-color: #eef2f6;
  margin-top: 9px;
  margin-right: 35px;
  border-right: solid 20px;
  border-color: #eef2f6;
}
.tablerowhead {
  width: 50px;
  font-size: 14px;
  padding: 16px;
}
.tablerowbody {
  width: 70px;
  font-size: 14px;
  padding: 16px;
}
.secondcontainer {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.tablehead {
  background-color: #eef2f6;
}
.table {
  min-width: 650px;
}

.tableCell {
  border-bottom: 1px solid #ddd;
  /* padding: 8px; */
  text-align: center;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  border-top: solid 20px;
  border-color: #eef2f6;
  margin-bottom: 16px;
  padding-top: 16px;
}

.button {
  color: #fff;
  background-color: #1976d2;
  &:hover {
    background-color: #1565c0;
  }
  margin-left: 8px;
  margin-left: 1;
  margin-right: 1;
}
